import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { removePageFromCart, saveBonCommande, removePageSeao, removePageAffiliation, removePageDisplay, addToCart, clearCarte } from "../actions/swipple";
import { Link, useNavigate } from 'react-router-dom';
import '../style/Home.css';
import '../style/MaSalection.css';
import '../style/responsive/maSelection.css';
import Modal from "react-modal";
import { pdf } from '@react-pdf/renderer';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Font, Svg, Path } from '@react-pdf/renderer';
import BonCommandeDocument from '../view/pdf/Boncommande';
import { logOut } from "../actions/user";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Slider from 'rc-slider';
import GlobalModal from '../view/includes/modal';


const MaSelection = ({ setShowMySelection, totalSeao, totalAffiliation, totalDisplay }) => {
    const navigate = useNavigate();
    const totalHT = totalSeao + totalAffiliation + totalDisplay;
    const dispatch = useDispatch();
    const [activeSelectionTab, setActiveSelectionTab] = useState('SEAO')
    const { cartItems, pricing, savedCommande } = useSelector(state => state.services); // Récupérer les données
    const { user } = useSelector(state => state.user); // Récupérer les données

    const [isPopupVisible, setPopupVisible] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [selectedDuration, setSelectedDuration] = useState('6mois');

    const handleChangeSeaoData = (selectedSiteName, element, date, duree) => {
        element.startDate = date
        element.duree = duree

        if(element.option === 'Exclusivité'){
            if(duree === '6mois')
                element.seao_total_price = element.price3
            else
                element.seao_total_price = element.price4
        }else{
            if(duree === '6mois')
                element.seao_total_price = element.price
            else
                element.seao_total_price = element.price2
        }

        dispatch(addToCart('seao', selectedSiteName, [element]));
    };
    const handleChangeSeaoOption = (selectedSiteName, element, valeur) => {
        element.option = (element.option === valeur ? null : valeur)

        if((element.option === valeur ? null : valeur) === 'Exclusivité'){
            if(element.duree === '6mois')
                element.seao_total_price = element.price3
            else
                element.seao_total_price = element.price4
        }else{
            if(element.duree === '6mois')
                element.seao_total_price = element.price
            else
                element.seao_total_price = element.price2
        }

        dispatch(addToCart('seao', selectedSiteName, [element]));
    };

    const handleChangeAffiliationData = (selectedSiteName, element, date) => {
        element.startDate = date
        dispatch(addToCart('affiliation', selectedSiteName, element));
    };
    const handleChangeAffiliationOption = (selectedSiteName, element, valeur) => {
        element.option = (element.option === valeur ? null : valeur)
        dispatch(addToCart('affiliation', selectedSiteName, element));
    };

    const handleChangeDisplayData = (selectedSiteName, element, date, duree) => {
        element.startDate = date
        element.duree = duree
        dispatch(addToCart('display', selectedSiteName, element));
    };
    const handleChangeDisplayType = (selectedSiteName, element, type) => {
        element.displayType = type
        
        let prixTotal = 0;
        element.option?.map(op => {
            if (op != 'Sous-traiter la création' && pricing) {
                if (pricing?.display[type][op]){
                    prixTotal += pricing?.display[type][op]
                }
                if (element.option.includes('Sous-traiter la création')) {
                    prixTotal += pricing?.display['visuel'][op]
                }
            }
        })
        element.display_total_price = prixTotal

        dispatch(addToCart('display', selectedSiteName, element));
    };
    const handleChangeDisplayOption = (selectedSiteName, element, valeur) => {
        if (element.option.includes(valeur)) {
            element.option = element.option.filter((selected) => selected !== valeur)
        } else {
            element.option = [...element.option, valeur]
        }

        let prixTotal = 0;
        element.option?.map(op => {
            if (op != 'Sous-traiter la création' && pricing) {
                if (pricing?.display[element.displayType][op]){
                    prixTotal += pricing?.display[element.displayType][op]
                }
                if (element.option.includes('Sous-traiter la création')) {
                    prixTotal += pricing?.display['visuel'][op]
                }
            }
        })
        element.display_total_price = prixTotal

        dispatch(addToCart('display', selectedSiteName, element));
    };

    // if (selectedOptions.includes(option)) {
    //     // dispatch(updateSiteDisplayOptions(selectedSiteId, selectedOptions.filter((selected) => selected !== option)));
    //     setSelectedOptions(selectedOptions.filter((selected) => selected !== option));
    // } else {
    //     // dispatch(updateSiteDisplayOptions(selectedSiteId, [...selectedOptions, option]));
    //     setSelectedOptions([...selectedOptions, option]);
    // }
    const handleShowPopup = (content) => {
        setModalContent(content);
        setPopupVisible(true);
    };

    const handleClosePopup = (event) => {
        if (event.target.id === 'popup-overlay' || event.target.className === 'modal-button') {
            setPopupVisible(false);
        }
    };

    const handleRemovePageSeao = (siteName, pageId) => {
        dispatch(removePageSeao(siteName, pageId));
    };
    const handleRemovePageAffiliation = (siteName) => {
        dispatch(removePageAffiliation(siteName));
    };
    const handleRemovePageDisplay = (siteName) => {
        dispatch(removePageDisplay(siteName));
    };

    const [modalNBMonthIsOpen, setmodalNBMonthIsOpen] = useState(false);
    const [months, setMonths] = useState(1);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalDateIsOpen, setModalDateIsOpen] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [isReserveSeao, setIsReserveSeao] = useState(false);
    const closeModalNbMonth = () => setmodalNBMonthIsOpen(false);
    const openModalNbMonth = () => setmodalNBMonthIsOpen(true);

    const seaoPages = cartItems?.seao || [];
    const affiliationPages = cartItems?.affiliation || [];
    const displayPages = cartItems?.display || [];
    console.log("displayPages");
    console.log(seaoPages);
    console.log(affiliationPages);


    const handleRemovePage = (type, siteKey, pageUrl) => {
        dispatch(removePageFromCart(type, siteKey, pageUrl));
    };

    const handleSelectionTabClick = (valeur) => {
        setActiveSelectionTab(valeur)
    }

    const handeHideSelection = () => {
        setShowMySelection(false);
        document.body.style.overflow = 'auto';
    }
    
    const handleReturnToHome = () => {
        // Clear all selections
        dispatch(clearCarte()); // Adjust arguments if needed
        setShowMySelection(false);
        setActiveSelectionTab(null); // Reset the active selection
        document.body.style.overflow = 'auto';
    };
    
    const handleOpenPage = (url) => {
        // Ensure the URL starts with 'http://' or 'https://'
        const formattedUrl = url.startsWith('http://') || url.startsWith('https://')
            ? url
            : `https://${url}`; // Default to 'https' if not provided
        window.open(formattedUrl, '_blank', 'noopener,noreferrer');
    };

    const handeleSubmitData = () => {
        const response = dispatch(saveBonCommande(cartItems, user));
        setModalBonOpen(true)
    }

    const openModalDate = () => setModalDateIsOpen(true);
    const closeModalDate = () => setModalDateIsOpen(false);

    const [modalOpen, setModalOpen] = useState(false);
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);
    const [isCheck, setIsCheck] = useState(false);

    const [modalBonOpen, setModalBonOpen] = useState(false);
    const openBonModal = () => setModalBonOpen(true);
    const closeBonModal = () => setModalBonOpen(false);

    const [pdfBlob, setPdfBlob] = useState(null);

    const formatDate = (date) => {
        const options = { weekday: 'short', day: 'numeric', month: 'short' };
        return new Date(date).toLocaleDateString('fr-FR', options);
    };

    // Generate PDF and set as blob for preview
    const generatePdf = async () => {
        const pdfDoc = <BonCommandeDocument />;
        const pdfBlob = await pdf(pdfDoc).toBlob();
        setPdfBlob(URL.createObjectURL(pdfBlob));
    };

    const logOutUser = () => {
        dispatch(logOut());
        navigate('/login');
    }

    // Custom styles for the modal
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            borderRadius: '26px',
            border: 'none',
            boxShadow: '0px 5px 10px rgba(0,0,0,0.1)',
            border: '1px solid var(--Soft-gray, #F6F6F6)',
            background: '#FFFFFF'
        },
    };


    const addMonths = (date, months) => {
        const result = new Date(date);
        result.setMonth(result.getMonth() + months);
        return result;
    };
    const removeMonths = (date, months) => {
        const result = new Date(date);
        result.setMonth(result.getMonth() - months);
        return result;
    };
    const handleChangeDate = (dateDebut, dateFin) => {
        // dispatch(updateSiteDate(selectedPageName, dateDebut, dateFin));
    };

    const handlePriceClick = (tabName) => {
        setActiveSelectionTab(tabName); // Set the active tab based on the clicked price
    };

    const [isModalVisible, setModalVisible] = useState(false);
    const [selectedSiteKey, setSelectedSiteKey] = useState(null);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [removeHandler, setRemoveHandler] = useState(null);

    const toggleModal = () => {
        setModalVisible(!isModalVisible);
    };

    const [isValidationModalVisible, setValidationModalVisible] = useState(false);
    const toggleValidationModal = () => {
        setValidationModalVisible(!isValidationModalVisible);
    };
    const [isOrderConfirmationModalVisible, setOrderConfirmationModalVisible] = useState(false);
    const handleValidateAndContinue = () => {
        setValidationModalVisible(false); // Hide the first modal
        setOrderConfirmationModalVisible(true); // Show the second modal
    };


    return (
        <>
            <div className="footer2 maSelectionWeb table_scrollSelection">

                <div className="d-flex justify-content-center hideSelction2" onClick={handeHideSelection}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="15" viewBox="0 0 20 20" fill="none">
                        <path d="M4.97284 7.5H15.0236C15.7189 7.5 16.0666 8.33984 15.5744 8.83203L10.551 13.8594C10.2463 14.1641 9.75019 14.1641 9.4455 13.8594L4.42206 8.83203C3.92988 8.33984 4.27753 7.5 4.97284 7.5Z" fill="#8D658A" />
                    </svg>
                    Fermer ma sélection
                </div>
                <div className="table_scrollSelection width100">
                    {
                        activeSelectionTab === "SEAO" && (
                            <>
                                {Object.entries(seaoPages).length === 0 || Object.entries(seaoPages).every(([siteKey, siteValue]) => siteValue.pages?.length === 0) ? (
                                    <div className="empty-selection-container">
                                        <p className="empty-selection-message">Votre sélection est vide !</p>
                                    </div>
                                ) : (
                                    Object.entries(seaoPages).map(([siteKey, siteValue]) => (
                                        <>
                                            {siteValue.pages?.map((item, index) => {
                                                // const pricingMatch = pricing.seao.find(
                                                //     (range) =>
                                                //         item.impressions >= range.min && item.impressions <= range.max
                                                // );
                                                let pricingMatch = pricing.seao.find((range) => {
                                                    if (range.min === range.max) {
                                                        return item.impressions >= range.min;
                                                    } else {
                                                        return item.impressions >= range.min && item.impressions <= range.max;
                                                    }
                                                });
                                                // if (pricingMatch) {
                                                //     const price = pricingMatch[item.duree] || 0;
                                                //     const exclusivityFee = item.option ? pricingMatch[item.option.toLowerCase()] || 0 : 0;
                                                //     const totalPrice = price + exclusivityFee;
                                                // } else {
                                                //     const totalPrice = 0;
                                                // }
                                                let totalPrice = 0
                                                if(item.option)
                                                    totalPrice = pricingMatch ? (pricingMatch['exclusivite_'+item.duree] || 0) : 0;
                                                else
                                                    totalPrice = pricingMatch ? (pricingMatch[item.duree] || 0) : 0;
                                                // const totalPrice = pricingMatch ? (pricingMatch[item.duree] || 0) + (item.option ? pricingMatch['exclusivite'] || 0 : 0) : 0;

                                                return (
                                                    <div className="seao-container">
                                                        <div key={siteKey} className="seao-header">
                                                            <div>
                                                                <div className="seao-icon">
                                                                    <img
                                                                        src={`http://www.google.com/s2/favicons?domain=${siteKey}`}
                                                                        alt="site icon"
                                                                        className="icon-img"
                                                                    />
                                                                </div>
                                                                <h1 className="seao-title">{siteKey}</h1>
                                                            </div>
                                                            <p className="seao-link" onClick={() => handleOpenPage(siteKey + '' + item?.page)}>Voir le site</p>
                                                        </div>
                                                        <div className="seao-content">
                                                            <p className="seao-content-item">Page :</p>
                                                            <p className="seao-content-item-two">{item.page}</p>
                                                            <p className="seao-content-item">Requête google:</p>
                                                            <p className="seao-content-item-two">{item.query}
                                                                {item?.mot_cles?.length} mots clés (
                                                                    {(() => {
                                                                        const combinedKeywords = item?.mot_cles?.map(motCle => motCle?.query).join(', ') || '';
                                                                        return combinedKeywords.length > 35
                                                                            ? `${combinedKeywords.slice(0, 35)}...`
                                                                            : combinedKeywords;
                                                                    })()}
                                                                )
                                                            </p>
                                                        </div>
                                                        <div className="seao-content-wrapper">
                                                            <p className="seao-title-config">Configuration</p>
                                                            <div className="config-selection-container">
                                                                <div className="config-selection-box-container">
                                                                    <p className="config-selection-duration">Durée définie :</p>
                                                                    <div className="config-selection-box">
                                                                        <label
                                                                            className={`config-selection-duration sixmoisSelected ${item.duree === '6mois' ? 'active-selection' : ''}`}
                                                                            onClick={() => handleChangeSeaoData(siteKey, item, item.startDate, '6mois')}
                                                                        >
                                                                            6 mois
                                                                        </label>
                                                                        <label
                                                                            className={`config-selection-duration douzemoisSelected ${item.duree === '12mois' ? 'active-selection' : ''}`}
                                                                            onClick={() => handleChangeSeaoData(siteKey, item, item.startDate, '12mois')}
                                                                        >
                                                                            12 mois
                                                                        </label>
                                                                    </div>
                                                                </div>



                                                                <div className="config-selection-box-container">
                                                                    <p className="config-selection-duration">Date de début de campagne</p>
                                                                    <div className="config-selection-box-date">
                                                                        <DatePicker
                                                                            selected={item.startDate}
                                                                            className="date-input-selection"
                                                                            onChange={(date) => { handleChangeSeaoData(siteKey, item, date, item.duree); }}
                                                                            dateFormat="d/MM/yyyy"
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="exclusivity-select">

                                                                <label htmlFor="exclusivity" className="exclusivity-text">Exclusivité</label>
                                                                <input
                                                                    type="checkbox"
                                                                    value="Exclusivité"
                                                                    checked={item.option == 'Exclusivité' ? 'checked' : ''}
                                                                    // checked={pageInfos?.selectedOptions?.includes(option.value)}
                                                                    onChange={() => handleChangeSeaoOption(siteKey, item, "Exclusivité")}
                                                                    className="cursor-pointer exclusivity-checkbox"
                                                                />
                                                            </div>

                                                        </div>
                                                        <div className="custom-container">
                                                            <p className="custom-price">
                                                                {totalPrice} €
                                                            </p>
                                                        </div>
                                                        <p className="delete-campaign" onClick={() => handleRemovePageSeao(siteKey, item.id)}>Supprimer la campagne</p>

                                                    </div>
                                                );
                                            })}
                                        </>
                                    ))
                                )}
                            </>
                        )
                    }
                    {

                        activeSelectionTab === "Affiliation" && (
                            <>
                                {Object.entries(affiliationPages).length === 0 || Object.entries(affiliationPages).every(([siteKey, siteValue]) => !siteValue.startDate) ? (
                                    <div className="empty-selection-container">
                                        <p className="empty-selection-message">Votre sélection est vide !</p>
                                    </div>
                                ) : (
                                    Object.entries(affiliationPages).map(([siteKey, siteValue]) => (
                                        <div className="seao-container-aff" key={siteKey}>
                                            <div className="seao-header-aff">
                                                <div>
                                                    <div className="seao-icon">
                                                        <img
                                                            src={`http://www.google.com/s2/favicons?domain=${siteKey}`}
                                                            alt="site icon"
                                                            className="icon-img"
                                                        />
                                                    </div>
                                                    <h1 className="seao-title">{siteKey}</h1>
                                                </div>
                                                <p className="seao-link" onClick={() => handleOpenPage(siteKey)}>Voir le site</p>
                                            </div>
                                            <div className="seao-content-wrapper-aff">
                                                <p className="seao-title-config">Configuration</p>
                                                <div className="config-selection-container">
                                                    <div className="config-selection-box-container">
                                                        <p className="config-selection-duration">Durée définie :</p>
                                                        <div className={`config-popup-box-affiliation ${siteValue.duree === '12mois' ? 'selected' : ''}`}>
                                                            <label
                                                                className={`config-popup-duration-affiliation douzemois-affiliation`}
                                                                onClick={() => setSelectedDuration('12mois')}
                                                            >
                                                                12 mois
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="config-selection-box-container">
                                                        <p className="config-selection-duration">Date de début de campagne</p>
                                                        <div className="config-selection-box-date">
                                                            <DatePicker
                                                                selected={siteValue.startDate}
                                                                className="date-input-selection"
                                                                onChange={(date) => { handleChangeAffiliationData(siteKey, siteValue, date); }}
                                                                dateFormat="d/MM/yyyy"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="exclusivity-select">
                                                    <label htmlFor="exclusivity" className="exclusivity-text">Exclusivité</label>
                                                    <input
                                                        type="checkbox"
                                                        value="Exclusivité"
                                                        checked={siteValue.option === 'Exclusivité'}
                                                        onChange={() => handleChangeAffiliationOption(siteKey, siteValue, "Exclusivité")}
                                                        className="cursor-pointer exclusivity-checkbox"
                                                    />
                                                </div>
                                            </div>
                                            <p className="delete-campaign" onClick={() => handleRemovePageAffiliation(siteKey)}>Supprimer la campagne</p>
                                        </div>
                                    ))
                                )}
                            </>
                        )
                    }
                    {
                        activeSelectionTab == "Display" && (
                            <>
                                {Object.entries(displayPages).length === 0 || Object.entries(displayPages).every(([siteKey, siteValue]) => !siteValue.startDate) ? (
                                    <div className="empty-selection-container">
                                        <p className="empty-selection-message">Votre sélection est vide !</p>
                                    </div>
                                ) : (
                                    <>
                                        {Object.entries(displayPages).map(([siteKey, siteValue]) => {
                                            let priceTotalDisplay = 0;
                                            siteValue.option.map(op => {
                                                if (op != 'Sous-traiter la création') {
                                                    if (pricing?.display[siteValue.displayType])
                                                        priceTotalDisplay += pricing?.display[siteValue.displayType][op]
                                                    if (siteValue.option.includes('Sous-traiter la création')) {
                                                        priceTotalDisplay += pricing?.display['visuel'][op]
                                                    }
                                                }
                                            })
                                            return (
                                                <div className="seao-container-display">
                                                    <div className="seao-header-display">
                                                        <div>
                                                            <div className="seao-icon">
                                                                <img
                                                                    src={`http://www.google.com/s2/favicons?domain=${siteKey}`}
                                                                    alt="site icon"
                                                                    className="icon-img"
                                                                />
                                                            </div>
                                                            <h1 className="seao-title">{siteKey}</h1>
                                                        </div>
                                                        <p className="seao-link" onClick={() => handleOpenPage(siteKey)}>Voir le site</p>
                                                    </div>
                                                    <div className="seao-content-wrapper-display">
                                                        <p className="seao-title-config">Configuration</p>
                                                        <div className="config-selection-container">
                                                            {/* <div className="config-selection-box-container">
                                                    <p className="config-selection-duration">Durée définie :</p>
                                                    <div style={styles.sliderContainer2}>
                                                        <span>{siteValue.duree} mois</span>
                                                        <Slider
                                                            min={1}
                                                            max={12}
                                                            value={siteValue.duree}
                                                            // onChange={(value) => setMonths(value)}
                                                            onChange={(value) => { handleChangeDisplayData(siteKey, siteValue, siteValue.startDate, value); }}
                                                            trackStyle={styles.slider.trackStyle}    // Apply the track color
                                                            handleStyle={styles.slider.handleStyle}  // Apply the thumb color
                                                            railStyle={styles.slider.railStyle}      // Apply the rail color 
                                                            style={styles.slider}
                                                        />
                                                    </div>
                                                </div> */}
                                                            <div className="config-selection-box-container">
                                                                <p className="config-selection-duration">Durée définie :</p>
                                                                <div className={`config-popup-box-affiliation ${siteValue.duree === '12' ? 'selected' : 'selected'}`}>
                                                                    <label
                                                                        className={`config-popup-duration-affiliation douzemois-affiliation`}
                                                                        onClick={() => setMonths('12')}
                                                                    >
                                                                        12 mois
                                                                    </label>
                                                                </div>
                                                            </div>


                                                            <div className="config-selection-box-container">
                                                                <p className="config-selection-duration">Date de début de campagne</p>
                                                                <div className="config-selection-box-date">
                                                                    <DatePicker
                                                                        selected={siteValue.startDate}
                                                                        className="date-input-selection"
                                                                        // onChange={(date) => { setStartDate(date); }}
                                                                        onChange={(date) => { handleChangeDisplayData(siteKey, siteValue, date, siteValue.duree); }}
                                                                        dateFormat="d/MM/yyyy"
                                                                    />
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="row" style={{ justifyContent: 'center' }}>
                                                            <div className="col-md-12" style={{ paddingRight: '0', textAlign: 'center' }}>
                                                                <div className="exclusivity-select" style={{ display: 'inline-flex', marginBottom: '10px' }}>
                                                                    <label htmlFor="exclusivity" className="exclusivity-text">Carré</label>
                                                                    <input
                                                                        type="checkbox"
                                                                        value="Carré"
                                                                        checked={siteValue?.option?.includes('Carré') ? 'checked' : ''}
                                                                        onChange={() => handleChangeDisplayOption(siteKey, siteValue, 'Carré')}
                                                                        className="cursor-pointer exclusivity-checkbox"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12" style={{ paddingRight: '0', textAlign: 'center' }}>
                                                                <div className="exclusivity-select" style={{ display: 'inline-flex', marginBottom: '10px' }}>
                                                                    <label htmlFor="exclusivity" className="exclusivity-text">Bannière header</label>
                                                                    <input
                                                                        type="checkbox"
                                                                        value="Bannière header"
                                                                        checked={siteValue?.option?.includes('Bannière header') ? 'checked' : ''}
                                                                        onChange={() => handleChangeDisplayOption(siteKey, siteValue, 'Bannière header')}
                                                                        className="cursor-pointer exclusivity-checkbox"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12" style={{ textAlign: 'center' }}>
                                                                <div className="exclusivity-select" style={{ display: 'inline-flex' }}>
                                                                    <label htmlFor="exclusivity" className="exclusivity-text">Habillage</label>
                                                                    <input
                                                                        type="checkbox"
                                                                        value="Habillage"
                                                                        checked={siteValue?.option?.includes('Habillage') ? 'checked' : ''}
                                                                        onChange={() => handleChangeDisplayOption(siteKey, siteValue, 'Habillage')}
                                                                        className="cursor-pointer exclusivity-checkbox"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br />

                                                        <div className="row">
                                                            <p className="config-popup-duration col-md-12 text-center">Display présent sur</p>
                                                            <div className="col-md-12 box-display">
                                                                <div className="config-popup-box">
                                                                    <label
                                                                        className={`config-popup-duration sixmois  ${siteValue?.displayType === 'all' ? 'selected' : ''}`}
                                                                        onClick={() => handleChangeDisplayType(siteKey, siteValue, 'all')}
                                                                    >
                                                                        Tout le site
                                                                    </label>
                                                                    <label
                                                                        className={`config-popup-duration douzemois  ${siteValue?.displayType === 'home' ? 'selected' : ''}`}
                                                                        onClick={() => handleChangeDisplayType(siteKey, siteValue, 'home')}
                                                                    >
                                                                        Accueil
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br />
                                                        <div className="exclusivity-select">

                                                            <label htmlFor="exclusivity" className="exclusivity-text">Sous-traiter la création</label>
                                                            <input
                                                                type="checkbox"
                                                                value='Sous-traiter la création'
                                                                // checked={selectedOptions.includes(option.value)}
                                                                checked={siteValue.option?.includes('Sous-traiter la création') ? 'checked' : ''}
                                                                onChange={() => handleChangeDisplayOption(siteKey, siteValue, 'Sous-traiter la création')}
                                                                className="cursor-pointer exclusivity-checkbox"
                                                            />
                                                        </div>

                                                    </div>
                                                    <div className="custom-container">
                                                        <p className="custom-price">{priceTotalDisplay} €</p>
                                                    </div>
                                                    <p className="delete-campaign" onClick={() => handleRemovePageDisplay(siteKey)}>Supprimer la campagne</p>

                                                </div>
                                            );
                                        })}
                                    </>
                                )}
                            </>
                        )
                    }


                </div>
                <div className="footer">
                    <div className="button-group">
                        <div className={activeSelectionTab === 'SEAO' ? 'activeText' : ''} onClick={() => handlePriceClick('SEAO')}>
                            <span className="textSelection">SEOA</span>
                            <span
                                className={`priceSelection ${activeSelectionTab === 'SEAO' ? 'activePriceSelection' : ''}`}

                            >
                                {totalSeao} €
                            </span>
                        </div>
                        <div className={activeSelectionTab === 'Affiliation' ? 'activeText' : ''} onClick={() => handlePriceClick('Affiliation')}>
                            <span className="textSelection">Affiliation</span>
                            <span
                                className={`priceSelection ${activeSelectionTab === 'Affiliation' ? 'activePriceSelection' : ''}`}

                            >
                                {totalAffiliation} €
                            </span>
                        </div>
                        <div className={activeSelectionTab === 'Display' ? 'activeText' : ''} onClick={() => handlePriceClick('Display')}>
                            <span className="textSelection">Display</span>
                            <span
                                className={`priceSelection ${activeSelectionTab === 'Display' ? 'activePriceSelection' : ''}`}

                            >
                                {totalDisplay} €
                            </span>
                        </div>
                    </div>
                    <button className="large-button" onClick={openModal}>Valider ma sélection</button>
                </div>

            </div>

            <div className="maSelectionMobile">
                <div className="header-container">
                    <button className="retour-button" onClick={handeHideSelection}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <path d="M8.24988 2.98446V9.0155C8.24988 9.4331 7.74496 9.64224 7.44968 9.34695L4.43416 6.33143C4.25111 6.14839 4.25111 5.85157 4.43416 5.66853L7.44968 2.65301C7.74496 2.3577 8.24988 2.56686 8.24988 2.98446Z" fill="white" />
                        </svg>
                        Retour
                    </button>
                    <img src="assets/images/logo-swipple-ads.png" alt="Logo" className="logo-swipple" />
                </div>
                <div className="d-flex flex-column align-items-center w-100" style={{ marginTop: '38px' }}>
                    {/* <h1 className='title'>Vous êtes sur la campagne</h1> */}
                    <div className="d-flex justify-content-between w-90">
                        <button
                            className={`tab ${activeSelectionTab === 'SEAO' ? 'active' : ''}`}
                            onClick={() => handleSelectionTabClick('SEAO')}
                        >
                            SEAO
                        </button>
                        <button
                            className={`tab ${activeSelectionTab === 'Affiliation' ? 'active' : ''}`}
                            onClick={() => handleSelectionTabClick('Affiliation')}
                        >
                            Affiliation
                        </button>
                        <button
                            className={`tab ${activeSelectionTab === 'Display' ? 'active' : ''}`}
                            onClick={() => handleSelectionTabClick('Display')}
                        >
                            Display
                        </button>
                    </div>
                    <div className="contentContainer">
                        <div className="table_scrollSelection_mobile width100">
                            {
                                activeSelectionTab === "SEAO" && (
                                    <>
                                        {Object.entries(seaoPages).length === 0 || Object.entries(seaoPages).every(([siteKey, siteValue]) => siteValue.pages?.length === 0) ? (
                                            <div className="empty-selection-container">
                                                <p className="empty-selection-message">Votre sélection est vide !</p>
                                            </div>
                                        ) : (
                                            Object.entries(seaoPages).map(([siteKey, siteValue]) => (
                                                <>
                                            {siteValue.pages?.map((item, index) => {

                                                let pricingMatch = pricing.seao.find((range) => {
                                                    if (range.min === range.max) {
                                                        return item.impressions >= range.min;
                                                    } else {
                                                        return item.impressions >= range.min && item.impressions <= range.max;
                                                    }
                                                });
                                                // if (pricingMatch) {
                                                //     const price = pricingMatch[item.duree] || 0;
                                                //     const exclusivityFee = item.option ? pricingMatch[item.option.toLowerCase()] || 0 : 0;
                                                //     const totalPrice = price + exclusivityFee;
                                                // } else {
                                                //     const totalPrice = 0;
                                                // }

                                                let totalPrice = 0
                                                if(item.option)
                                                    totalPrice = pricingMatch ? (pricingMatch['exclusivite_'+item.duree] || 0) : 0;
                                                else
                                                    totalPrice = pricingMatch ? (pricingMatch[item.duree] || 0) : 0;
                                                // const totalPrice = pricingMatch ? (pricingMatch[item.duree] || 0) + (item.option ? pricingMatch['exclusivite'] || 0 : 0) : 0;
                                                return (
                                                    <div className="seao-container-mobile">
                                                        <div key={siteKey} className="seao-header">
                                                            <div>
                                                                <div className="seao-icon">
                                                                    <img
                                                                        src={`http://www.google.com/s2/favicons?domain=${siteKey}`}
                                                                        alt="site icon"
                                                                        className="icon-img"
                                                                    />
                                                                </div>
                                                                <h1 className="seao-title-mobile">{siteKey}</h1>
                                                            </div>
                                                            <p className="seao-link" onClick={() => handleOpenPage(siteKey + '' + item?.page)}>Voir le site</p>
                                                        </div>
                                                        <div className="scrollBody">
                                                            <div className="seao-content-mobile">
                                                                <p className="seao-content-item">Page : {item.impressions}</p>
                                                                <p className="seao-content-item-two">{item.page}</p>
                                                                <p className="seao-content-item">Requête google:</p>
                                                                <p className="seao-content-item-two">{item.query}</p>
                                                            </div>
                                                            <div className="seao-content-wrapper-mobile">
                                                                <p className="seao-title-config">Configuration</p>
                                                                <div className="config-selection-container-mobile">
                                                                    <div className="config-selection-box-container">
                                                                        <p className="config-selection-duration">Durée définie :</p>
                                                                        <div className="config-selection-box">
                                                                            <label
                                                                                className={`config-selection-duration sixmoisSelected ${item.duree === '6mois' ? 'active-selection' : ''}`}
                                                                                onClick={() => handleChangeSeaoData(siteKey, item, item.startDate, '6mois')}
                                                                            >
                                                                                6 mois
                                                                            </label>
                                                                            <label
                                                                                className={`config-selection-duration douzemoisSelected ${item.duree === '12mois' ? 'active-selection' : ''}`}
                                                                                onClick={() => handleChangeSeaoData(siteKey, item, item.startDate, '12mois')}
                                                                            >
                                                                                12 mois
                                                                            </label>
                                                                        </div>
                                                                    </div>



                                                                    <div className="config-selection-box-container">
                                                                        <p className="config-selection-duration">Date de début de campagne</p>
                                                                        <div className="config-selection-box-date">
                                                                            <DatePicker
                                                                                selected={item.startDate}
                                                                                className="date-input-selection"
                                                                                onChange={(date) => { handleChangeSeaoData(siteKey, item, date, item.duree); }}
                                                                                dateFormat="d/MM/yyyy"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                </div>


                                                            </div>

                                                            <div className="seao-content-wrapper-mobile-op">
                                                                <p className="seao-title-config">Options</p>
                                                                <div className="exclusivity-select">

                                                                    <label htmlFor="exclusivity" className="exclusivity-text">Exclusivité</label>
                                                                    <input
                                                                        type="checkbox"
                                                                        value="Exclusivité"
                                                                        checked={item.option == 'Exclusivité' ? 'checked' : ''}
                                                                        // checked={pageInfos?.selectedOptions?.includes(option.value)}
                                                                        onChange={() => handleChangeSeaoOption(siteKey, item, "Exclusivité")}
                                                                        className="cursor-pointer exclusivity-checkbox"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="custom-container-mobile">
                                                            <p className="custom-price">
                                                                {totalPrice} €
                                                            </p>
                                                        </div>
                                                        <p
                                                            className="delete-campaign-mobile"
                                                            onClick={() => {
                                                                setSelectedSiteKey(siteKey); // Set the siteKey
                                                                setSelectedItemId(item.id); // Set the item ID
                                                                setRemoveHandler(() => handleRemovePageSeao); // Assign the specific handler
                                                                toggleModal(); // Open the modal
                                                            }}
                                                        >
                                                            Supprimer la campagne
                                                        </p>

                                                    </div>
                                                          );
                                                        })}
                                                    </>
                                                ))
                                            )}
                                        </>
                                    )
                                }
                            {
                                activeSelectionTab === "Affiliation" && (
                                    <>
                                        {Object.entries(affiliationPages).length === 0 || Object.entries(affiliationPages).every(([siteKey, siteValue]) => !siteValue.startDate) ? (
                                            <div className="empty-selection-container">
                                                <p className="empty-selection-message">Votre sélection est vide !</p>
                                            </div>
                                        ) : (
                                            Object.entries(affiliationPages).map(([siteKey, siteValue]) => (
                                        <div className="seao-container-aff-mobile">
                                            <div className="seao-header-aff">
                                                <div>
                                                    <div className="seao-icon">
                                                        <img
                                                            src={`http://www.google.com/s2/favicons?domain=${siteKey}`}
                                                            alt="site icon"
                                                            className="icon-img"
                                                        />
                                                    </div>
                                                    <h1 className="seao-title-mobile">{siteKey}</h1>
                                                </div>
                                                <p className="seao-link" onClick={() => handleOpenPage(siteKey)}>Voir le site</p>
                                            </div>
                                            <div className="seao-content-wrapper-mobile">
                                                <p className="seao-title-config">Configuration</p>
                                                <div className="config-selection-container-mobile">
                                                    <div className="config-selection-box-container">
                                                        <p className="config-selection-duration">Durée définie :</p>
                                                        <div className={`config-popup-box-affiliation ${siteValue.duree === '12mois' ? 'selected' : 'selected'}`}>
                                                            <label
                                                                className={`config-popup-duration-affiliation douzemois-affiliation`}
                                                                onClick={() => setSelectedDuration('12mois')}
                                                            >
                                                                12 mois
                                                            </label>
                                                        </div>
                                                    </div>



                                                    <div className="config-selection-box-container">
                                                        <p className="config-selection-duration">Date de début de campagne</p>
                                                        <div className="config-selection-box-date">
                                                            <DatePicker
                                                                selected={siteValue.startDate}
                                                                className="date-input-selection"
                                                                // onChange={(date) => { setStartDate(date); }}
                                                                onChange={(date) => { handleChangeAffiliationData(siteKey, siteValue, date); }}
                                                                dateFormat="d/MM/yyyy"
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="exclusivity-select">

                                                    <label htmlFor="exclusivity" className="exclusivity-text">Exclusivité</label>
                                                    <input
                                                        type="checkbox"
                                                        value="Exclusivité"
                                                        // checked={siteValue.option?.includes('Exclusivité') ? 'checked':''}
                                                        checked={siteValue.option == 'Exclusivité' ? 'checked' : ''}
                                                        onChange={() => handleChangeAffiliationOption(siteKey, siteValue, "Exclusivité")}
                                                        className="cursor-pointer exclusivity-checkbox"
                                                    />
                                                </div>

                                            </div>
                                            <div className="seao-content-wrapper-mobile-op">
                                                <p className="seao-title-config">Options</p>
                                                <div className="exclusivity-select">

                                                    <label htmlFor="exclusivity" className="exclusivity-text">Exclusivité</label>
                                                    <input
                                                        type="checkbox"
                                                        value="Exclusivité"
                                                        checked={siteValue.option == 'Exclusivité' ? 'checked' : ''}
                                                        // checked={pageInfos?.selectedOptions?.includes(option.value)}
                                                        onChange={() => handleChangeSeaoOption(siteKey, siteValue, "Exclusivité")}
                                                        className="cursor-pointer exclusivity-checkbox"
                                                    />
                                                </div>
                                            </div>
                                            <div className="custom-container">
                                                <p className="custom-price">{totalAffiliation} €</p>
                                            </div>
                                            <p
                                                className="delete-campaign"
                                                onClick={() => {
                                                    setSelectedSiteKey(siteKey); // Set the current siteKey
                                                    setRemoveHandler(() => handleRemovePageAffiliation); // Set the specific handler
                                                    toggleModal(); // Open the modal
                                                }}
                                            >
                                                Supprimer la campagne
                                            </p>

                                        </div>
                                  ))
                                  )}
                              </>
                          )
                      }
                            {
                                activeSelectionTab == "Display" && (
                                    <>
                                        {Object.entries(displayPages).length === 0 || Object.entries(displayPages).every(([siteKey, siteValue]) => !siteValue.startDate) ? (
                                            <div className="empty-selection-container">
                                                <p className="empty-selection-message">Votre sélection est vide !</p>
                                            </div>
                                        ) : (
                                            <>
                                                {Object.entries(displayPages).map(([siteKey, siteValue]) => {
                                        let priceTotalDisplay = 0;
                                        siteValue.option.map(op => {
                                            if (op != 'Sous-traiter la création') {
                                                if (pricing?.display[siteValue.displayType])
                                                    priceTotalDisplay += pricing?.display[siteValue.displayType][op]
                                                if (siteValue.option.includes('Sous-traiter la création')) {
                                                    priceTotalDisplay += pricing?.display['visuel'][op]
                                                }
                                            }
                                        })
                                        return (
                                            <div className="seao-container-display-mobile">
                                                <div className="seao-header-display">
                                                    <div>
                                                        <div className="seao-icon">
                                                            <img
                                                                src={`http://www.google.com/s2/favicons?domain=${siteKey}`}
                                                                alt="site icon"
                                                                className="icon-img"
                                                            />
                                                        </div>
                                                        <h1 className="seao-title-mobile">{siteKey}</h1>
                                                    </div>
                                                    <p className="seao-link" onClick={() => handleOpenPage(siteKey)}>Voir le site</p>
                                                </div>
                                                <div className="scrollBodyDisplay">
                                                    <div className="seao-content-wrapper-mobile">
                                                        <p className="seao-title-config">Configuration</p>
                                                        <div className="config-selection-container-mobile">
                                                            {/* <div className="config-selection-box-container">
                                                                <p className="config-selection-duration">Durée définie :</p>
                                                                <div style={styles.sliderContainer2}>
                                                                    <span>{siteValue.duree} mois</span>
                                                                    <Slider
                                                                        min={1}
                                                                        max={12}
                                                                        value={siteValue.duree}
                                                                        // onChange={(value) => setMonths(value)}
                                                                        onChange={(value) => { handleChangeDisplayData(siteKey, siteValue, siteValue.startDate, value); }}
                                                                        trackStyle={styles.slider.trackStyle}    // Apply the track color
                                                                        handleStyle={styles.slider.handleStyle}  // Apply the thumb color
                                                                        railStyle={styles.slider.railStyle}      // Apply the rail color 
                                                                        style={styles.slider}
                                                                    />
                                                                </div>
                                                            </div> */}
                                                            <div className="config-selection-box-container">
                                                                <p className="config-selection-duration">Durée définie :</p>
                                                                <div className={`config-popup-box-affiliation ${siteValue.duree === '12' ? 'selected' : 'selected'}`}>
                                                                    <label
                                                                        className={`config-popup-duration-affiliation douzemois-affiliation`}
                                                                        onClick={() => setMonths('12')}
                                                                    >
                                                                        12 mois
                                                                    </label>
                                                                </div>
                                                            </div>


                                                            <div className="config-selection-box-container">
                                                                <p className="config-selection-duration">Date de début de campagne</p>
                                                                <div className="config-selection-box-date">
                                                                    <DatePicker
                                                                        selected={siteValue.startDate}
                                                                        className="date-input-selection"
                                                                        // onChange={(date) => { setStartDate(date); }}
                                                                        onChange={(date) => { handleChangeDisplayData(siteKey, siteValue, date, siteValue.duree); }}
                                                                        dateFormat="d/MM/yyyy"
                                                                    />
                                                                </div>
                                                            </div>

                                                        </div>


                                                    </div>
                                                    <div className="seao-content-wrapper-mobile-disp">
                                                        <p className="seao-title-config">Options</p>
                                                        <div className="exclusivity-select">
                                                            <label htmlFor="exclusivity" className="exclusivity-text">Carré</label>
                                                            <input
                                                                type="checkbox"
                                                                value='Carré'
                                                                checked={siteValue.option?.includes('Carré') ? 'checked' : ''}
                                                                onChange={() => handleChangeDisplayOption(siteKey, siteValue, 'Carré')}
                                                                className="cursor-pointer exclusivity-checkbox"
                                                            />
                                                        </div>
                                                        <div className="exclusivity-select">
                                                            <label htmlFor="exclusivity" className="exclusivity-text">Bannière header</label>
                                                            <input
                                                                type="checkbox"
                                                                value='Bannière header'
                                                                checked={siteValue.option?.includes('Bannière header') ? 'checked' : ''}
                                                                onChange={() => handleChangeDisplayOption(siteKey, siteValue, 'Bannière header')}
                                                                className="cursor-pointer exclusivity-checkbox"
                                                            />
                                                        </div>
                                                        <div className="exclusivity-select">
                                                            <label htmlFor="exclusivity" className="exclusivity-text">Habillage</label>
                                                            <input
                                                                type="checkbox"
                                                                value='Habillage'
                                                                checked={siteValue.option?.includes('Habillage') ? 'checked' : ''}
                                                                onChange={() => handleChangeDisplayOption(siteKey, siteValue, 'Habillage')}
                                                                className="cursor-pointer exclusivity-checkbox"
                                                            />
                                                        </div>

                                                        <div className="row">
                                                            <p className="config-popup-duration centreP col-md-12">Display présent sur </p>
                                                            <div className="col-md-12 box-display">
                                                                <div className="config-popup-box">
                                                                    <label
                                                                        className={`config-popup-duration sixmois  ${siteValue?.displayType === 'all' ? 'selected' : ''}`}
                                                                        onClick={() => handleChangeDisplayType(siteKey, siteValue, 'all')}
                                                                    >
                                                                        Tout le site
                                                                    </label>
                                                                    <label
                                                                        className={`config-popup-duration douzemois  ${siteValue?.displayType === 'home' ? 'selected' : ''}`}
                                                                        onClick={() => handleChangeDisplayType(siteKey, siteValue, 'home')}
                                                                    >
                                                                        Accueil
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="exclusivity-select">
                                                            <label htmlFor="exclusivity" className="exclusivity-text">Sous-traiter la création</label>
                                                            <input
                                                                type="checkbox"
                                                                value='Sous-traiter la création'
                                                                // checked={selectedOptions.includes(option.value)}
                                                                checked={siteValue.option?.includes('Sous-traiter la création') ? 'checked' : ''}
                                                                onChange={() => handleChangeDisplayOption(siteKey, siteValue, 'Sous-traiter la création')}
                                                                className="cursor-pointer exclusivity-checkbox"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="custom-container">
                                                    <p className="custom-price">{priceTotalDisplay} €</p>
                                                </div>
                                                <p className="delete-campaign" onClick={() => {
                                                    setSelectedSiteKey(siteKey); // Set the current siteKey
                                                    setRemoveHandler(() => handleRemovePageDisplay); // Assign the specific handler
                                                    toggleModal(); // Open the modal
                                                }}
                                                >Supprimer la campagne</p>

                                            </div>
                                                       );
                                                    })}
                                                </>
                                            )}
                                        </>
                                    )
                                }


                        </div>
                    </div>
                </div>
                <div className="valider-selection" onClick={toggleValidationModal}>
                    Valider ma sélection
                </div>
            </div>

            {isModalVisible && (
                <div className="selection-popup-overlay" onClick={toggleModal}>
                    <div
                        className="selection-popup"
                        onClick={(e) => e.stopPropagation()} // Prevent closing modal on content click
                    >
                        <h2 className="titleModal">Supprimer une campagne</h2>
                        <p className="pModal">
                            Vous vous apprêtez à supprimer cette campagne. Cette action est irréversible
                            et entraînera la perte définitive de toutes les données associées.
                            <br /><br /> Êtes-vous sûr de vouloir continuer ?
                        </p>
                        <div className="button-containerD">
                            <button className="CancelP" onClick={toggleModal}>Annuler</button>
                            <button
                                className="deleteP"
                                onClick={() => {
                                    removeHandler(selectedSiteKey, selectedItemId); // Pass both parameters
                                    toggleModal(); // Close the modal
                                }}
                            >
                                Supprimer
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {isValidationModalVisible && (
                <div className="selection-popup-overlay" onClick={toggleValidationModal}>
                    <div
                        className="selection-popup"
                        onClick={(e) => e.stopPropagation()} // Prevent closing modal on content click
                    >
                        <h2 className="titleModal">Information</h2>
                        <p className="pModal">
                            Je reconnais être informé que la position dans Google des sites que j'ai choisi peut évoluer. Je ne tiendrais pas responsable Swipple de cette situation et je continue ma démarche en connaissance de cette situation.
                        </p>
                        <div className="button-containerD">
                            <button className="CancelP" onClick={toggleValidationModal}>Retour</button>
                            <button
                                className="deleteP"
                                onClick={handleValidateAndContinue}
                            >
                                Valider et continuer
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {isOrderConfirmationModalVisible && (
                <div className="selection-popup-overlay" onClick={() => setOrderConfirmationModalVisible(false)}>
                    <div
                        className="selection-popup"
                        onClick={(e) => e.stopPropagation()} // Prevent closing modal on content click
                    >
                        <h2 className="titleModal">Bon de commande envoyé</h2>
                        <p className="pModal">
                            Nous avons bien reçu votre demande et vous allez également recevoir un récapitulatif de votre campagne ainsi que le bon de commande. <br /> <br />
                            Nous vous recontacterons dans les plus brefs délais ! <br /> <br />
                            Merci pour votre confiance.

                        </p>
                        {/* <div className="button-containerD"> */}
                        <button className="deleteP" onClick={handleReturnToHome}>
                            Revenir à l’accueil
                        </button>
                        {/* </div> */}
                    </div>
                </div>
            )}



            <GlobalModal
                isVisible={isPopupVisible}
                modalContent={modalContent}
                handleClose={handleClosePopup}
            />

            <Modal
                isOpen={modalNBMonthIsOpen}
                onRequestClose={closeModalNbMonth}
                style={customStyles}
                contentLabel="Date Range Picker"
            >
                <h2 className='modal-title'>Nombre de mois de campagne</h2>

                <div style={styles.sliderContainer}>
                    <span>{months} mois</span>
                    <Slider
                        min={1}
                        max={12}
                        value={months}
                        onChange={(value) => setMonths(value)}
                        trackStyle={styles.slider.trackStyle}    // Apply the track color
                        handleStyle={styles.slider.handleStyle}  // Apply the thumb color
                        railStyle={styles.slider.railStyle}      // Apply the rail color 
                        style={styles.slider}
                    />
                </div>

                <div style={styles.dateContainer}>
                    {isReserveSeao ? (
                        <>
                            <div style={styles.interval}>
                                <div style={styles.intervalDate} onClick={openModalDate}>{formatDate(startDate)}</div>
                                <div> au </div>
                                <div style={styles.intervalDate} onClick={openModalDate}>{formatDate(endDate)}</div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div style={styles.interval}>
                                <button style={styles.intervalDate} onClick={openModalDate}>Date de début</button>
                                <div> au </div>
                                <button style={styles.intervalDate} onClick={openModalDate}>Date de fin</button>
                            </div>
                        </>
                    )}
                </div>
                {isReserveSeao ? (
                    <div className='modal-resv-save' style={styles.button}>
                        <button onClick={closeModalNbMonth}>
                            Enregistrer
                        </button>
                    </div>
                ) : ''}
            </Modal>


            <Modal
                isOpen={modalOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Date Range Picker"
            >

                <div style={styles.container}>
                    <div>
                        <input
                            type="checkbox"
                            onChange={(event) => setIsCheck(event.target.checked)}
                            checked={isCheck}
                        />
                    </div>
                    <div style={{ marginLeft: '10px' }}>
                        Je reconnais être informé que la position dans Google des sites que j'ai choisi <br />
                        peut évoluer. Je ne tiendra pas responsable Swipple de cette situation et je <br />
                        continue ma démarche en connaissance de cette situation.</div>
                </div>
                <div className='modal-valider' style={styles.button}>
                    <button onClick={handeleSubmitData} className={isCheck ? 'active' : ''}
                        disabled={!isCheck}>
                        Valider
                    </button>
                </div>
            </Modal>

            <Modal
                isOpen={modalBonOpen}
                onRequestClose={closeBonModal}
                style={customStyles}
                contentLabel="Date Range Picker"
            >
                {
                    !savedCommande ?
                    <>
                        <div style={{ textAlign: 'center' }}>
                            <div className="orange-color">
                                <img src="assets/images/loading1.gif" alt="" style={{ width: "50px" }} />
                                Veuillez patienter s'il vous plait !
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <h2 className='modal-title bon_bon'>Bon de commande envoyé</h2>
                        <div style={styles.container}>
                            <div style={{ marginLeft: '10px' }}>
                                Nous avons bien reçu votre demande et vous allez également recevoir un <br />
                                récapitulatif de votre campagne ainsi que le bon de commande. <br /> <br />
                                Nous vous recontacterons dans les plus brefs délais ! <br /><br /> Merci pour votre confiance.
                            </div>
                        </div>
                        <div className='modal-bon' style={styles.button_bon}>
                            <button className="cree_button2" onClick={handleReturnToHome}>
                                Revenir à l’accueil
                            </button>
                            {/* <button className="fermer" onClick={logOutUser}>
                                Se déconnecter
                            </button> */}
                            {/* <PDFDownloadLink
                                document={<BonCommandeDocument seaoPages={seaoPages} affiliationPages={affiliationPages} displayPages={displayPages} totalHT={totalHT} user={user} />}
                                fileName="bon_commande.pdf"
                                style={{ textDecoration: 'none', color: '#4CAF50', fontSize: '18px' }}
                            >
                                {({ loading }) => (
                                    <button className="cree_button" style={{ padding: '10px 20px', fontSize: '16px' }}>
                                        {loading ? 'Loading document...' : 'Créer une nouvelle campagne'}
                                    </button>
                                )}
                            </PDFDownloadLink> */}
                        </div>
                    </>
                }
            </Modal>

            <Modal
                isOpen={modalDateIsOpen}
                onRequestClose={closeModalDate}
                style={customStyles}
                contentLabel="Date Range Picker"
            >
                <span className='modal-close' onClick={closeModalDate}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                        <path d="M18.0476 3.90456C14.1414 -0.00163102 7.81169 -0.00163102 3.90549 3.90456C-0.00070092 7.81076 -0.00070092 14.1405 3.90549 18.0467C7.81169 21.9529 14.1414 21.9529 18.0476 18.0467C21.9538 14.1405 21.9538 7.81076 18.0476 3.90456ZM14.284 15.8798C14.0958 16.0679 13.7879 16.0679 13.5997 15.8798L10.9766 13.2566L8.35342 15.8798C8.16524 16.0679 7.85731 16.0679 7.66913 15.8798L6.07243 14.2831C5.88425 14.0949 5.88425 13.787 6.07243 13.5988L8.69557 10.9756L6.07243 8.35249C5.88425 8.16431 5.88425 7.85638 6.07243 7.6682L7.66913 6.0715C7.85731 5.88332 8.16524 5.88332 8.35342 6.0715L10.9766 8.69464L13.5997 6.0715C13.7879 5.88332 14.0958 5.88332 14.284 6.0715L15.8807 7.6682C16.0689 7.85638 16.0689 8.16431 15.8807 8.35249L13.2576 10.9756L15.8807 13.5988C16.0689 13.787 16.0689 14.0949 15.8807 14.2831L14.284 15.8798Z" fill="#FF8366" />
                    </svg>
                </span>
                <h2 className='modal-title'>Sélectionner la date de début de campagne</h2>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '10px' }}>
                    <div>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => {
                                setStartDate(date); setIsReserveSeao(true); setEndDate(addMonths(date, months));
                                handleChangeDate(date, addMonths(date, months));
                            }}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            dateFormat="MMMM d, yyyy"
                            inline
                        />
                    </div>
                    <div>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => {
                                setEndDate(date); setStartDate(removeMonths(date, months));
                                handleChangeDate(removeMonths(date, months), date);
                            }}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            dateFormat="MMMM d, yyyy"
                            inline
                        />
                    </div>
                </div>
                <div className='modal-save-aff'>
                    <button onClick={closeModalDate} style={{ background: !isReserveSeao ? 'linear-gradient(90.07deg, #9D9D9C -27.35%, #E5E1DB 77.87%)' : 'var(--Active-gradient, linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%))' }}
                        disabled={!isReserveSeao}>
                        Enregistrer
                    </button>
                </div>
            </Modal>

        </>
    );
}

const styles = {
    sliderContainer2: {
        // display: 'flex',
        // alignItems: 'center',
        // margin: '20px 0',
        // justifyContent: 'center',
        color: '#FF8366'
    },
    container: {
        display: 'flex',
        alignItems: 'stretch',
        width: '497px',
        fontSize: '12px',
    },
    button: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '30px',
        gap: '30px',
    },
    button_bon: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '30px',
        gap: '30px',
    },

    sliderContainer: {
        display: 'flex',
        alignItems: 'center',
        margin: '20px 0',
        justifyContent: 'center',
        color: '#FF8366'
    },
    slider: {
        width: '150px',
        margin: '0 10px',
        trackStyle: { backgroundColor: '#FF8366' },  // Customize active track color
        handleStyle: { borderColor: '#FF8366' },     // Customize thumb/handle color
        railStyle: { backgroundColor: '#ccc' },
    },
    dateContainer: {
        display: 'flex',
        justifyContent: 'between',
        marginBottom: '20px',
        fontSize: '18px',
        marginRight: '60px'
    },
    button: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '40px',
        gap: '30px',
    },
    interval: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '249px',
        height: '18px',
        gap: '40px',
        fontFamily: 'Poppins',
        fontSize: '12px',
        lineHeight: '18px',
        textAlign: 'left',
        marginLeft: '57px',
    },
    intervalDate: {
        all: 'unset',
        cursor: 'pointer',
        fontWeight: '600',
        textDecoration: 'underline',
    }
};

export default MaSelection;
